import { createContext, useContext, useState, useRef } from "react";

export const BtnContext = createContext();

export const BtnProvider = ({ children }) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const [commonProductCategories, setCommonProductCategories] = useState([]);
  const [logisticProviders, setLogisticProviders] = useState([]);
  const [preferredBillingPlans, setPreferredBillingPlans] = useState([]);
  const [category, setCategory] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const [isExchange, setIsExchange] = useState(false);
  const [returnDays, setReturnDays] = useState(0);
  const [exchangeDays, setExchangeDays] = useState(0);
  const [reduceReturns, setReduceReturns] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [controlRefundFraud, setControlRefundFraud] = useState(false);
  const [neighborhoodExchangePreferred, setNeighborhoodExchangePreferred] =
    useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get("uid");
  const dayRef = useRef(14);
  return (
    <BtnContext.Provider
      value={{
        isBtnDisabled,
        setIsBtnDisabled,
        isRegistrationComplete,
        setIsRegistrationComplete,
        category,
        setCategory,
        uid,
        logistics,
        setLogistics,
        isExchange,
        setIsExchange,
        commonProductCategories,
        setCommonProductCategories,
        logisticProviders,
        setLogisticProviders,
        preferredBillingPlans,
        setPreferredBillingPlans,
        returnDays,
        setReturnDays,
        exchangeDays,
        setExchangeDays,
        reduceReturns,
        setReduceReturns,
        controlRefundFraud,
        setControlRefundFraud,
        neighborhoodExchangePreferred,
        setNeighborhoodExchangePreferred,
        redirectUrl,
        setRedirectUrl,
        dayRef,
      }}
    >
      {children}
    </BtnContext.Provider>
  );
};
export const useBtnContext = () => {
  return useContext(BtnContext);
};
