import React, { useState, useEffect } from "react";
import { LeftSideSignInUp, ForgotUserName } from "../Components";
import { content } from "../Data/data";

export const ForgotUser = () => {
  const [leftHeading, setLeftHeading] = useState("");
  const [leftBottomImgA, setLeftBottomImgA] = useState("");
  const [leftBottomImgB, setLeftBottomImgB] = useState("");
  const [animateLeft, setAnimateLeft] = useState("");

  useEffect(() => {
    (() => {
      setAnimateLeft("animate__slideInLeft");
      content.data.slice(0, 1).map((item) => {
        setLeftBottomImgA(item.imgName[0]);
        setLeftBottomImgB(item.imgName[1]);
      });
    })();
  }, []);

  return (
    <div className="content">
      <LeftSideSignInUp
        LeftSideTitleText={leftHeading}
        LeftSideImgSrcA={leftBottomImgA}
        LeftSideImgSrcB={leftBottomImgB}
        LeftSideAnimation={animateLeft}
      />
      <ForgotUserName />
    </div>
  );
};
