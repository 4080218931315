import React, { useState, useEffect } from "react";
import { useBtnContext } from "../../contexts";
import { YesNo } from "..";

export const RightTitle = ({ TitleText, paddingStyle }) => {
  const [showMultipleTitle, setShowMultipleTitle] = useState();
  const [countYesNo, setCountYesNo] = useState(0);
  const {
    setIsBtnDisabled,
    setReduceReturns,
    setControlRefundFraud,
    setNeighborhoodExchangePreferred,
  } = useBtnContext();
  const [selectedIndex, setSelectedIndex] = useState([]);
  useEffect(() => {
    setShowMultipleTitle(TitleText);
  }, [TitleText]);

  const countYesNoHandler = (index, e) => {
    if (index === 0) {
      e.target.value === "yes"
        ? setReduceReturns(true)
        : setReduceReturns(false);
    } else if (index === 1) {
      e.target.value === "yes"
        ? setControlRefundFraud(true)
        : setControlRefundFraud(false);
    } else if (index === 2) {
      e.target.value === "yes"
        ? setNeighborhoodExchangePreferred(true)
        : setNeighborhoodExchangePreferred(false);
    }

    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    setSelectedIndex((prev) => [...prev, index]);

    const uniqueIndex = selectedIndex?.filter(distinct);

    if (uniqueIndex.length === 3) {
      setSelectedIndex(uniqueIndex);
    }
  };

  useEffect(() => {
    if (showMultipleTitle?.length > 1) {
      if (selectedIndex?.length >= 3) {
        setIsBtnDisabled(false);
      } else {
        setIsBtnDisabled(true);
      }
    }
  }, [countYesNo, showMultipleTitle, setIsBtnDisabled, selectedIndex]);
  return (
    <>
      {showMultipleTitle?.length > 1 ? (
        <>
          {showMultipleTitle?.map((item, index) => {
            return (
              <div className="right_title_main" key={index}>
                <h2 className="inner__title right__title">{item}</h2>
                <YesNo
                  name={`multiple_question${index}`}
                  onClick={(e) => {
                    countYesNoHandler(index, e);
                  }}
                />
              </div>
            );
          })}
        </>
      ) : (
        <h2 className="inner__title">{TitleText}</h2>
      )}
    </>
  );
};
