import React from "react";
import { LeftSideImg } from "../LeftSide/LeftSideImg";
import { LeftTitle } from "../LeftSide/LeftTitle";
export const LeftSideSignInUp = ({
  LeftSideImgSrcA,
  LeftSideImgSrcB,
  LeftSideAnimation,
}) => {
  return (
    <div className="side">
      <div className="left">
        <div className="left__inner">
          <svg className="icon__bg-L icon__bg">
            <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
          </svg>
          <svg className="icon__bg-R icon__bg">
            <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
          </svg>
          <LeftSideImg
            SrcA={LeftSideImgSrcA}
            SrcB={LeftSideImgSrcB}
            animation={LeftSideAnimation}
          />
        </div>
        <div className={`left__content animate__animated animate__slideInLeft`}>
          <img src="assets/images/logo.webp" alt="logo" className="logo" />
          <div className="inner__desc" style={{ color: "white" }}>
            WELCOME TO
          </div>
          <div style={{ width: "fit-content" }}>
            <LeftTitle TitleText={"EcoReturns"} />
            <hr className="line" />
            <div className="signup__sub">
              Please generate your login credentials for admin account. You can
              add more users to your organisation later.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
