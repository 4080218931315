import { useState } from "react";
import axios from "axios";

const InstallEcoreturns = () => {
  const [store, setStore] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [ctaText, setCTAText] = useState("Install EcoReturns");
  const [ctaDisabled, setCTADisabled] = useState(false);

  async function handleInstallEcoreturns(event) {
    event.preventDefault();

    // Initialize the procees
    setErrorMessage("");
    setCTAText("Checking...");
    setCTADisabled(true);

    // If store is empty
    if (!store || store === "") {
      setErrorMessage("Please enter store title");
      setCTAText("Install EcoReturns");
      setCTADisabled(false);
      return;
    }

    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/common/install-ecoreturns?store=${store.replace(/ /g, "")}`
    );

    const { error, message } = response.data;

    if (error) {
      setErrorMessage(message);
      setCTAText("Install EcoReturns");
      setCTADisabled(false);
      return;
    }

    // If store name is valid, redirect to URL
    const { redirect_url } = response.data;

    window.location = redirect_url;
    setCTADisabled(false);
  }

  const errorContainer = (
    <div className="page__container__content__form__error">
      <p className="page__container__content__form__error__text">
        {errorMessage}
      </p>
    </div>
  );

  return (
    <main className="page">
      <section className="page__container">
        <div className="page__container__content">
          <div className="page__container__content__header">
            <img
              src="assets/images/brand_logo.webp"
              alt="logo"
              className="page__container__content__header__logo"
            />
          </div>
          <div className="page__container__content__form">
            <div className="page__container__content__form__header">
              <h3 className="page__container__content__form__header__heading">
                Get Started with EcoReturns
              </h3>
            </div>
            <form className="page__container__content__form__main">
              <div className="page__container__content__form__main__section">
                <input
                  required
                  type="text"
                  className="page__container__content__form__main__section__input"
                  onChange={(event) => setStore(event.target.value)}
                  placeholder="Enter Shopify store name"
                />
                <span className="page__container__content__form__main__section__text">
                  .myshopify.com
                </span>
              </div>
              {errorMessage && errorContainer}
              <div className="page__container__content__form__main__action">
                <button
                  className="page__container__content__form__main__action__item"
                  onClick={(event) => handleInstallEcoreturns(event)}
                  disabled={ctaDisabled}
                >
                  {ctaText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export { InstallEcoreturns };
