import React from "react";
import { Link } from "react-router-dom";
import "./errors.css";
export const E500 = () => {
  return (
    <div className="container500">
      <div className="seventy500">
        <div className="content500">
          <h1 className="heading1" style={{ fontWeight: "bold" }}>
            Ooops,
            <br />
            Something went
            <br />
            wrong
          </h1>
          <h6 className="heading6">But did we just reduce a return?</h6>
          <Link to="/">
            <button className="pink-btn500">REFRESH</button>
          </Link>
        </div>
        <img
          className="carbon-img500"
          src="assets/reduce-carbon.png"
          alt="500-error"
        />
      </div>
      <div className="thirty500"></div>
    </div>
  );
};
