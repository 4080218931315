import React, { useState, useEffect } from "react";
import { LeftSide, RightSide } from "../Components";
import { useBtnContext } from "../contexts";
import { useNavigate } from "react-router-dom";
import { content } from "../Data/data";
import axios from "axios";
import "animate.css";
export const Home = () => {
  const [nextValue, setNextValue] = useState(1);

  const [leftHeading, setLeftHeading] = useState("");
  const [leftBottomImgA, setLeftBottomImgA] = useState("");
  const [leftBottomImgB, setLeftBottomImgB] = useState("");
  const [rightHeading, setRightHeading] = useState("");
  const [rightDescription, setRightDescription] = useState("");
  const [isYesNo, setIsYesNo] = useState(false);
  const [yesActionText, setYesActionText] = useState("");
  const [category, setCategory] = useState([]);
  // const [logistics, setLogistics] = useState([]);
  const [animateLeft, setAnimateLeft] = useState();
  const [animateRight, setAnimateRight] = useState();
  const [activePage, setActivePage] = useState();
  const navigate = useNavigate();
  const {
    uid,
    setIsRegistrationComplete,
    isRegistrationComplete,
    setCommonProductCategories,
    commonProductCategories,
    setLogisticProviders,
    logisticsProviders,
    setPreferredBillingPlans,
    setIsBtnDisabled,
  } = useBtnContext();

  // useEffect(() => {
  //   if (localStorage.getItem("ResumeState")>1) {
  //     setNextValue((prevValue) => localStorage.getItem("ResumeState"));
  //   } else {
  //     setNextValue(1);
  //   }
  // }, []);

  const loadContent = () => {
    setIsBtnDisabled(true);
    if (nextValue > 0 && nextValue < 6) {
      setAnimateLeft("animate__slideOutLeft");
      setAnimateRight("animate__slideOutUp");
      setTimeout(() => {
        if (nextValue === 4) {
          setNextValue((prevValue) => prevValue + 2);
          // setNextValue((prevValue) => prevValue + 1);
        } else {
          setNextValue((prevValue) => prevValue + 1);
        }

        setAnimateLeft("animate__slideInLeft");
        setAnimateRight("animate__slideInUp");
        localStorage.setItem("ResumeState", nextValue);
      }, 400);
    } else {
      //Route to last page
      if (isRegistrationComplete != true) {
        navigate("/Sign-up");
      } else {
        navigate("/Conclusion");
      }

      // window.location.href = "https://calendly.com/";
      // window.open("https://calendly.com/sauravp/ecoreturns", "_blank");
      // setTimeout(() => {
      //   window.location.href = `${process.env.REACT_APP_API_URL}/return-portal`;
      // }, 500);

      // setNextValue(1);
    }
  };

  //http://localhost:3000/?uid=gAAAAABiHGqZLFWVHm6vgfW93n03raLfvKGcfwXYzvadipa8golNDD20ufBp1Tc5rbXoPpNz0qxk-Rn4gelhs_YKpr1UVe2hMVTRgqJw0yVSAY-otBpooMw=

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/onboarding?uid=${uid}`
        );

        if (response?.status === 200) {
          localStorage.setItem("uid", uid);
          localStorage.setItem("StoreName", response?.data?.store_subdomain);
          localStorage.setItem("StoreEmail", response?.data?.store_data.email);
          setIsRegistrationComplete(
            response?.data?.store_data.registration_complete
          );
          setCommonProductCategories(response?.data?.common_product_categories);
          setLogisticProviders(response?.data?.logistic_providers);
          setPreferredBillingPlans(response?.data?.preferred_billing_plans);

          // localStorage.setItem(
          //   "CommonProductCategories",
          //   response?.data?.common_product_categories
          // );
          // localStorage.setItem(
          //   "LogisticProviders",
          //   response?.data?.logistic_providers
          // );
          // localStorage.setItem(
          //   "PreferredBillingPlans",
          //   response?.data?.preferred_billing_plans
          // );
        }
      } catch (error) {
        if (error?.response?.status === 500) {
          navigate("/internal-server-error");
        }
      }
    })();
  }, []);

  useEffect(() => {
    (() => {
      content.data.slice(nextValue - 1, nextValue).map((item) => {
        return (
          setLeftHeading(item.leftHeading),
          setLeftBottomImgA(item.imgName[0]),
          setLeftBottomImgB(item.imgName[1]),
          setRightHeading(item.rightHeading),
          setRightDescription(item.rightDescription),
          setIsYesNo(item.isShowYesNo),
          // setCategory(item.category),
          // setLogistics(item.logisticsDropDown),
          setActivePage(item.pageNo),
          setYesActionText(item.YesActionNext)
        );
      });
    })();
  }, [nextValue]);

  return (
    <div className="content">
      <LeftSide
        LeftSideTitleText={leftHeading}
        LeftSideImgSrcA={leftBottomImgA}
        LeftSideImgSrcB={leftBottomImgB}
        LeftSideAnimation={animateLeft}
      />
      <RightSide
        RightSideTitleText={rightHeading}
        InnerDesc={rightDescription}
        isShowYesNo={isYesNo}
        CheckBoxText={commonProductCategories}
        Logistics={logisticsProviders}
        LoadContent={loadContent}
        RightSideAnimation={animateRight}
        ActivePage={activePage}
        LastPage={5}
        YesActionText={yesActionText}
      />
    </div>
  );
};
