import "./App.css";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "./pages";
import routes from "./routing/routes";
import insp, { install } from "inspectlet-es";

const App = () => {
  /* Inspectlet Code */
  process.env.REACT_APP_ENABLE_INSPECTLET &&
    install(process.env.REACT_APP_INSPECTLET_WID);
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_INSPECTLET) {
      insp(["identify", localStorage.getItem("StoreName")]);
      window.__insp.push(["identify", localStorage.getItem("StoreName")]);
    }
  });

  useEffect(() => {
    const browserTab = document.querySelector("#browser__tab"),
      browserText = browserTab.textContent;

    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        browserTab.innerHTML = "👋 Come back 😭";
      } else {
        browserTab.innerHTML = "Thanks 🤗";
        setTimeout(() => {
          browserTab.innerHTML = browserText;
        }, 1000);
      }
    });
  }, []);

  return (
    <div className="workspace">
      <Routes>
        {routes.map((route, key) => (
          <Route path={route.path} element={<route.component />} key={key} />
        ))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
