import React, { useState, useEffect } from "react";
export const TextInput = ({
  type,
  name,
  place,
  onChangeValue,
  onClick,
  iconName = "",
  isShowIcon = false,
  defaultValue = "",
  flag,
}) => {
  const [textValue, setTextValue] = useState("");
  const handleChange = (e) => {
    setTextValue(e.target.value);
    onChangeValue(e.target.value);
  };

  useEffect(() => {
    if (flag === "email") {
      setTextValue(localStorage.getItem("StoreEmail"));
    }
  }, []);

  return (
    <div className="text__wrapper">
      <label className="text__label">
        <div className="text__label__heading">{name}</div>
        <div className="input-icons">
          <input
            type={type}
            className="text__input"
            placeholder={place}
            onChange={handleChange}
            value={textValue}
            onClick={onClick}
          />
          {isShowIcon && (
            <img
              src={`assets/svg/${iconName}`}
              alt="show-hide-password"
              className="icon"
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </label>
    </div>
  );
};
