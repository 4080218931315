import React, { useEffect, useState } from "react";
import { useBtnContext } from "../contexts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./slot.css";
export const SlotBooking = () => {
  const [seconds, setSeconds] = useState(10);
  const { uid, redirectUrl } = useBtnContext();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("ResumeState", 1);
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    const uid = localStorage.getItem("uid");

    if (seconds === 2) {
      (async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=onboarding-complete`
          );

          if (response.status === 200) {
            if (!redirectUrl) {
              window.location.href = `${process.env.REACT_APP_API_URL}/home`;
            } else {
              window.location.href = redirectUrl;
            }
          }
        } catch (error) {
          if (error.response.status === 500) {
            navigate("/internal-server-error");
          }
        }
      })();
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const redirectHandler = () => {
    window.open("https://calendly.com/sauravp/ecoreturns", "_blank");
    window.focus();
  };

  return (
    <>
      <div className="finaly slide-down animate__animated animate__slideInUp ">
        <svg className="finaly__bg-L icon__bg">
          <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
        </svg>
        <svg className="finaly__bg-R icon__bg">
          <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
        </svg>
        <img src="assets/images/brand_logo.webp" alt="logo" className="logo" />
        <h2 className="finaly__title">
          <br />
          We are preparing your return portal based on your preference.
        </h2>

        <p className="finaly__desc">
          Meanwhile, we strongly recommend you to use our free concierge
          service. Book a free onboarding session. Clicking below will not
          disrupt your current flow.
        </p>
        <div className="btn_main">
          {/* <a
            href="https://calendly.com/sauravp/ecoreturns"
            target="_blank"
            onclick="redirectHandler"
            rel="noreferrer"
          > */}
          <button type="button" onClick={redirectHandler} className="book_demo">
            Book
          </button>
          {/* </a> */}
        </div>
        <div className="finaly__redirect">
          This page will automatically redirect in
          <p className="finaly__seconds timer"> {seconds} seconds</p>
        </div>
      </div>
    </>
  );
};
