import React from "react";
import Loader from "./Loader";
import "./button.css";

export const Button = ({
  onClick,
  isBtnDisabled,
  name,
  isLoader,
  className,
  isShowIcon = true,
}) => {
  return (
    <button
      type="button"
      className={`btn__continue ${className} ${
        isBtnDisabled ? "btn_disabled" : ""
      }`}
      onClick={onClick}
      disabled={isBtnDisabled}
    >
      {name}
      {isShowIcon && (
        <svg className="icon__arrow">
          <use xlinkHref="assets/svg/sprite.svg#arrow-right"></use>
        </svg>
      )}
    </button>
  );
};
