import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button";
import { TextInput } from "../TextInput/TextInput";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { useBtnContext } from "../../contexts";

export const RightSideSign = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [terms, setTerms] = useState(false);
  const [repeatPass, setRepeatPass] = useState("");
  const [phone, setPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState([false, false, 1]);
  const [errorPass, setErrorPass] = useState([false, false, 1]);
  const [errorPassLen, setErrorPassLen] = useState([false, false, 1]);
  const [errorPhone, setErrorPhone] = useState([false, false, 1]);
  const [errorTerms, setErrorTerms] = useState(false);
  const [errorMain, setErrorMain] = useState([]);
  const [passShow, setPassShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [existingEmailError, setExistingEmailError] = useState("");

  const navigate = useNavigate();
  const onEmailChange = (newValue) => {
    setEmail(newValue);
  };
  const onPassChange = (newValue) => {
    setPass(newValue);
  };
  const onRepeatPassChange = (newValue) => {
    setRepeatPass(newValue);
  };
  const onPhoneChange = (newValue) => {
    setPhone(newValue);
  };

  const handleCheckbox = (e) => {
    setTerms(e.target.checked);
  };

  const { setRedirectUrl } = useBtnContext();

  const validation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email) {
      setErrorEmail(() => {
        return [true, false, 0];
      });
    } else if (regex.test(email) === false && email) {
      setErrorEmail(() => {
        return [false, true, 0];
      });
    } else {
      setErrorEmail(() => {
        return [false, false, 1];
      });
    }

    if (!pass) {
      setErrorPassLen(() => {
        return [true, false, 0];
      });
    } else if (pass.length < 8 && pass) {
      setErrorPassLen(() => {
        return [false, true, 0];
      });
    } else {
      setErrorPassLen(() => {
        return [false, false, 1];
      });
    }

    if (!repeatPass) {
      setErrorPass(() => {
        return [true, false, 0];
      });
    } else if (pass != repeatPass && repeatPass) {
      setErrorPass(() => {
        return [false, true, 0];
      });
    } else {
      setErrorPass(() => {
        return [false, false, 1];
      });
    }

    const regexPhone =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,5})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    if (!phone) {
      setErrorPhone(() => {
        return [true, false, 0];
      });
    } else if (regexPhone.test(phone) === false && phone) {
      setErrorPhone(() => {
        return [false, true, 0];
      });
    } else {
      setErrorPhone(() => {
        return [false, false, 1];
      });
    }

    if (terms === false) {
      setErrorTerms(true);
    } else {
      setErrorTerms(false);
    }
  };

  const handlesubmit = () => {
    setIsSubmit(true);
  };

  useEffect(async () => {
    const uid = localStorage.getItem("uid");
    if (
      errorEmail[2] === 1 &&
      errorPass[2] === 1 &&
      errorPassLen[2] === 1 &&
      errorPhone[2] === 1 &&
      errorTerms === false &&
      isSubmit === true &&
      isLoading == false
    ) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=registration`,
          {
            username: email,
            new_password1: pass,
            new_password2: repeatPass,
            operator_phone: phone,
          }
        );
        if (response?.status === 200) {
          localStorage.setItem("StoreEmail", null);
          const redirect_url = response?.data?.redirect_url;
          setRedirectUrl(redirect_url);
          setErrorMain([]);
          setIsLoading(false);
          navigate("/Conclusion");
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          setInterval(() => {
            if (errorMain != error?.response?.data.errors) {
              setErrorMain(error?.response?.data.errors);
            }
          });
        }
        if (error?.response?.status === 500) {
          setExistingEmailError(error?.response?.data.message);
          navigate("/internal-server-error");
        }
        if (error?.response?.status === 403) {
          setExistingEmailError(error?.response?.data.message);
        }
        setIsLoading(false);
      }

      setIsSubmit(false);
    }
  }, [
    errorEmail,
    errorPass,
    errorPassLen,
    isSubmit,
    errorPhone,
    errorTerms,
    errorMain,
    // isLoading,
  ]);

  useEffect(() => {
    setEmail(localStorage.getItem("StoreEmail"));
  }, []);
  const saveData = async () => {};

  return (
    <>
      <div className={`side`}>
        <div className="main">
          <div className={`right animate__animated animate__slideInUp`}>
            <section className={`show signup__content`}>
              <div className={`inner__box signup__inner`}>
                <div className="signup__heading">Sign Up</div>
                <div className="signup__input">
                  <TextInput
                    type="text"
                    onChangeValue={onEmailChange}
                    name="Email"
                    place="name@examplestore.com"
                    flag="email"
                  />
                  <div className="signup__input__explain">
                    {existingEmailError ? (
                      <>
                        <br />
                        <span className="code_red signup__input__explain">
                          {existingEmailError}
                        </span>{" "}
                        <div className="sign-in-link-main">
                          <Link to="/Sign-in">Sign in</Link>
                          <div className="signin__text">
                            &nbsp;to existing account
                          </div>
                        </div>
                      </>
                    ) : (
                      "This will be used for loggin in."
                    )}
                    {errorEmail.map((error, i) => {
                      if (error === true && i === 1) {
                        return (
                          <span className="code_red signup__input__explain">
                            {" "}
                            Email is not valid.
                          </span>
                        );
                      } else if (error === true && i === 0) {
                        return (
                          <span className="code_red signup__input__explain">
                            {" "}
                            *required
                          </span>
                        );
                      }
                    })}
                    {errorMain.map((error) => {
                      return (
                        <div className="code_red signup__input__explain">
                          {error}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="signup__input signup__input__flex">
                  <div className="text__label">
                    <TextInput
                      type={passShow ? "text" : "password"}
                      onChangeValue={onPassChange}
                      name="Password"
                      place="Enter your password"
                    />
                    <div className="signup__input__explain">
                      Please enter a strong password
                      {errorPassLen.map((error, i) => {
                        if (error === true && i === 1) {
                          return (
                            <div className="code_red signup__input__explain">
                              {" "}
                              Passwords should be of minimum 8 characters
                            </div>
                          );
                        } else if (error === true && i === 0) {
                          return (
                            <span className="code_red signup__input__explain">
                              {" "}
                              *required
                            </span>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div style={{ width: "10%" }} />
                  <div className="text__label">
                    <TextInput
                      type={passShow ? "text" : "password"}
                      onChangeValue={onRepeatPassChange}
                      name="Repeat password"
                      place="Repeat your password"
                    />
                    {errorPass.map((error, i) => {
                      if (error === true && i === 1) {
                        return (
                          <div className="code_red signup__input__explain">
                            {" "}
                            Passwords do not match.
                          </div>
                        );
                      } else if (error === true && i === 0) {
                        return (
                          <span className="code_red signup__input__explain">
                            {" "}
                            *required
                          </span>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="signup__input">
                  {/* <TextInput
                    type="phone"
                    onChangeValue={onPhoneChange}
                    name="Recovery Phone"
                    place="+91 4578902421"
                    value={`+ ${phone}`}
                  /> */}
                  <PhoneInput
                    country={"us"}
                    isValid={(inputNumber, country, countries) => {
                      return countries.some((country) => {
                        return (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                        );
                      });
                    }}
                    placeholder="Enter phone number"
                    onChange={onPhoneChange}
                  />
                  <span className="signup__input__explain">
                    This will be used to recover the password.
                  </span>

                  {errorPhone.map((error, i) => {
                    if (error === true && i === 1) {
                      return (
                        <div className="code_red signup__input__explain">
                          {" "}
                          Invalid Phone Number
                        </div>
                      );
                    } else if (error === true && i === 0) {
                      return (
                        <span className="code_red signup__input__explain">
                          {" "}
                          *required
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="signup__input__submit">
                <div className="term_and_signin_block">
                  <label className="signup__checkbox">
                    <input
                      type="checkbox"
                      className="checkbox__input"
                      onChange={handleCheckbox}
                    />
                    <span className="checkbox__span">
                      <svg className="checkbox__icon">
                        <use xlinkHref="assets/svg/sprite.svg#choose"></use>
                      </svg>
                    </span>
                    <div className="signup__checkbox__text">
                      I agree with the{" "}
                      <a
                        href="https://saara.io/term-and-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="signup__checkbox__span">
                          Terms of Use
                        </span>{" "}
                      </a>
                      and{" "}
                      <a
                        href="https://saara.io/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="signup__checkbox__span">
                          Privacy Policy
                        </span>
                      </a>
                    </div>
                    {errorTerms ? (
                      <div className="code_red signup__input__explain">
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; Accept the terms!
                      </div>
                    ) : (
                      " "
                    )}
                  </label>
                  <div className="sign-in-link-main">
                    <Link to="/Sign-in">Sign in </Link>
                    <div className="signin__text">
                      &nbsp;to existing account
                    </div>
                  </div>
                </div>

                <Button
                  name={
                    isLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "SIGN UP"
                    )
                  }
                  isBtnDisabled={isLoading ? true : false}
                  className=""
                  onClick={() => {
                    validation();
                    saveData();
                    handlesubmit();
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
