export const LANDING = "/";
export const HOME = "/Home";
export const SIGNUP = "/Sign-up";
export const SIGNIN = "/Sign-in";
export const FORGOT_PASSWORD = "/ForgotPassword";
export const FORGOT_USERNAME = "/ForgotUserName";
export const ONBOARDING = "/onboarding";
export const SLOT_BOOKING = "/Conclusion";
export const PLAN = "/Plan";
export const ERROR500 = "/internal-server-error";
export const E500 = "/internal-server-error";
export const E404 = "/Page-Not-Found";
export const INSTALL_ECORETURNS_ROUTE = "/install";
