import React, { useState } from "react";
import { FullPageLoader } from "../Components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Plan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const planHandler = async (planType) => {
    const uid = localStorage.getItem("uid");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=plan&preferred_plan=${planType}`
      );
      if (response.status === 200) {
        setIsLoading(false);
        // navigate("/SlotBooking");
        window.location.href = "https://calendly.com/";
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        navigate("/internal-server-error");
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <div className="billing  overflow-yes">
          {/* <svg className="billing__bg-L  icon__bg">
            <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
          </svg>
          <svg className="billing__bg-R icon__bg">
            <use xlinkHref="assets/svg/sprite.svg#big-logo"></use>
          </svg> */}
          <img
            src="assets/images/logo-black.webp"
            alt="logo"
            className="logo"
          />
          <h2 className="billing__title">Billing information</h2>
          <p className="billing__desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            tincidunt dui scelerisque quis ut ornare risus.
          </p>
          <div className="billing__inner">
            <div className="billing__card">
              <div className="billing__card-content">
                <h3 className="billing__name">Freemium</h3>
                <div className="billing__rate">
                  <span className="billing__currency">$</span>
                  <span className="billing__price">0</span>
                  <span className="billing__mark">/</span>
                  <span className="billing__date">month</span>
                </div>
                <ul className="billing__list">
                  <li className="billing__item">
                    Return Policies:{" "}
                    <span className="condition__analytics">
                      {" "}
                      Single Condition based{" "}
                    </span>{" "}
                  </li>

                  <li className="billing__item">
                    Return Pictures :{" "}
                    <span className="condition__pic-collection">
                      {" "}
                      Single picture{" "}
                    </span>
                  </li>
                  <li className="billing__item">
                    Return Reasons:{" "}
                    <span className="condition__notification">Universal</span>{" "}
                  </li>
                  <li className="billing__item">
                    Dashboard:{" "}
                    <span className="condition__rules">Last 3 Months</span>{" "}
                  </li>
                  <li className="billing__item">
                    Analytics:{" "}
                    <span className="condition__exchange">
                      {" "}
                      Standard reports
                    </span>
                  </li>
                  <li className="billing__item">
                    Incentive engine:{" "}
                    <span className="condition__return-number">
                      Flat incentive
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Post purchase notifications:{" "}
                    <span className="condition__return-number">No</span>{" "}
                  </li>
                  <li className="billing__item">
                    Risk assessment:{" "}
                    <span className="condition__return-number">No</span>{" "}
                  </li>
                  <li className="billing__item">
                    Consumer side workflow:{" "}
                    <span className="condition__return-number">
                      Branded, Powered by EcoReturns
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Automation:{" "}
                    <span className="condition__return-number">None</span>{" "}
                  </li>
                  <li className="billing__item">
                    Customer notifications:{" "}
                    <span className="condition__return-number">
                      Branded Email
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Exchange:{" "}
                    <span className="condition__return-number">
                      Similar product
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Reverse Shipment payment:{" "}
                    <span className="condition__return-number">No</span>{" "}
                  </li>
                  <li className="billing__item">
                    User management:{" "}
                    <span className="condition__return-number">Default</span>{" "}
                  </li>
                  <li className="billing__item">
                    Return NPS:{" "}
                    <span className="condition__return-number">No</span>{" "}
                  </li>
                  <li className="billing__item">
                    Label generation:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    Recommerce:{" "}
                    <span className="condition__return-number">
                      Consumer facing on Return failure
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Integrations:{" "}
                    <span className="condition__return-number">2</span>{" "}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="billing__btn"
                value="free"
                onClick={(e) => {
                  planHandler(e.target.value);
                }}
              >
                Choose plan
              </button>
            </div>
            <div className="billing__card">
              <div className="billing__card-content">
                <h3 className="billing__name">Advanced</h3>
                <div className="billing__rate">
                  <span className="billing__currency">$</span>
                  <span className="billing__price">7</span>
                  <span className="billing__mark">/</span>
                  <span className="billing__date">month</span>
                </div>
                <ul className="billing__list">
                  <li className="billing__item">
                    Return Policies:{" "}
                    <span className="condition__analytics">
                      {" "}
                      Multiple Conditions based{" "}
                    </span>{" "}
                  </li>

                  <li className="billing__item">
                    Return Pictures :{" "}
                    <span className="condition__pic-collection">
                      {" "}
                      Multiple picture{" "}
                    </span>
                  </li>
                  <li className="billing__item">
                    Return Reasons:{" "}
                    <span className="condition__notification">
                      Multiple category
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Dashboard:{" "}
                    <span className="condition__rules">Last 12 Months</span>{" "}
                  </li>
                  <li className="billing__item">
                    Analytics:{" "}
                    <span className="condition__exchange">
                      {" "}
                      Standard reports + User reports
                    </span>
                  </li>
                  <li className="billing__item">
                    Incentive engine:{" "}
                    <span className="condition__return-number">
                      Rule based incentive
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Post purchase notifications:{" "}
                    <span className="condition__return-number">No</span>{" "}
                  </li>
                  <li className="billing__item">
                    Risk assessment:{" "}
                    <span className="condition__return-number">
                      5 flags per month
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Consumer side workflow:{" "}
                    <span className="condition__return-number">
                      Branded, Powered by EcoReturns
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Automation:{" "}
                    <span className="condition__return-number">
                      5 types, 50 Automation, Single rule based
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Customer notifications:{" "}
                    <span className="condition__return-number">
                      Branded Email + SMS
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Exchange:{" "}
                    <span className="condition__return-number">
                      All products
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Reverse Shipment payment:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    User management:{" "}
                    <span className="condition__return-number">
                      Default + 2
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Return NPS:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    Label generation:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    Recommerce:{" "}
                    <span className="condition__return-number">
                      Consumer and Merchange facing Post Return
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Integrations:{" "}
                    <span className="condition__return-number">5</span>{" "}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="billing__btn"
                value="advanced"
                onClick={(e) => {
                  planHandler(e.target.value);
                }}
              >
                Choose plan
              </button>
            </div>
            <div className="billing__card">
              <div className="billing__card-content">
                <h3 className="billing__name">Ultimate</h3>
                <div className="billing__rate">
                  <span className="billing__currency">$</span>
                  <span className="billing__price">23</span>
                  <span className="billing__mark">/</span>
                  <span className="billing__date">month</span>
                </div>
                <ul className="billing__list">
                  <li className="billing__item">
                    Return Policies:{" "}
                    <span className="condition__analytics">
                      {" "}
                      Multiple Conditions based{" "}
                    </span>{" "}
                  </li>

                  <li className="billing__item">
                    Return Pictures :{" "}
                    <span className="condition__pic-collection">
                      {" "}
                      AI based picture{" "}
                    </span>
                  </li>
                  <li className="billing__item">
                    Return Reasons:{" "}
                    <span className="condition__notification">
                      deeper reasons
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Dashboard:{" "}
                    <span className="condition__rules">Last 24 Months</span>{" "}
                  </li>
                  <li className="billing__item">
                    Analytics:{" "}
                    <span className="condition__exchange">
                      {" "}
                      User and TAT reports
                    </span>
                  </li>
                  <li className="billing__item">
                    Incentive engine:{" "}
                    <span className="condition__return-number">
                      AI powered incentives
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Post purchase notifications:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    Risk assessment:{" "}
                    <span className="condition__return-number">
                      50 flags per month
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Consumer side workflow:{" "}
                    <span className="condition__return-number">
                      Fully Branded without Powered by EcoReturns
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Automation:{" "}
                    <span className="condition__return-number">
                      10 types, 250 Automation, Multiple rule based
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Customer notifications:{" "}
                    <span className="condition__return-number">
                      Branded Email + SMS + Whatsapp
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Exchange:{" "}
                    <span className="condition__return-number">
                      All products
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Reverse Shipment payment:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    User management:{" "}
                    <span className="condition__return-number">
                      Default + 4
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Return NPS:{" "}
                    <span className="condition__return-number">
                      Yes with Text feedback
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Label generation:{" "}
                    <span className="condition__return-number">Yes</span>{" "}
                  </li>
                  <li className="billing__item">
                    Recommerce:{" "}
                    <span className="condition__return-number">
                      Buy back program
                    </span>{" "}
                  </li>
                  <li className="billing__item">
                    Integrations:{" "}
                    <span className="condition__return-number">10</span>{" "}
                  </li>
                </ul>
              </div>
              <button
                type="button"
                value="custom"
                className="billing__btn"
                onClick={(e) => {
                  planHandler(e.target.value);
                }}
              >
                Choose plan
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
