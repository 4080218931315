import React from "react";
import "./e404.css";
export const E404 = () => {
  return (
    <div className="container404">
      <div className="seventy">
        <div className="content404">
          <h1 className="heading1" style={{ fontWeight: "bold" }}>
            404, page not
            <br />
            found
            <br />
          </h1>
          <h5 className="heading5">
            instead found eco-friendly and
            <br /> painless returns.
          </h5>
          {localStorage.getItem("Is404") === "true" ? (
            ""
          ) : (
            <a href="/">
              <button className="pink-btn">
                <span>HOME</span>
              </button>
            </a>
          )}
        </div>
        <img className="carbon-img" src="assets/404_not_found.jpg" alt="404" />
      </div>
      <div className="thirty"></div>
    </div>
  );
};
