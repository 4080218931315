import React, { useState } from "react";
import { Button } from "../Button/Button";
import { TextInput } from "../TextInput/TextInput";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useBtnContext } from "../../contexts";

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const onEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const { setRedirectUrl } = useBtnContext();
  const handleSubmit = async () => {
    if (email !== "") {
      setIsLoading(true);
      try {
        const uid = localStorage.getItem("uid");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding/?uid=${uid}&param=reset_password`,
          {
            username: email,
          }
        );

        if (response?.status === 200) {
          const redirect_url = response?.data?.redirect_url;
          setRedirectUrl(redirect_url);
          setIsLoading(false);
          navigate("/Conclusion");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="side">
      <div className="main">
        <div className="right animate__animated animate__slideInUp">
          <section className="show fp__content">
            <div className="inner__box fp__inner">
              <div className="fp__heading">Forgot Password?</div>
              <div className="fp_label">
                <div className="username_email_label">
                  Username or Email address
                </div>
                <div className="fp_main">
                  <Link to="/ForgotUserName">Forgot Username?</Link>
                </div>
              </div>
              <div className="fp__input">
                <TextInput
                  type="text"
                  onChangeValue={onEmailChange}
                  name=""
                  place="Enter Username or Email address"
                />
              </div>
            </div>

            <div className="fp_btn_main">
              <Button
                name={
                  isLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "SUBMIT"
                  )
                }
                isBtnDisabled={isLoading ? true : false}
                className="fp_btn"
                isShowIcon={false}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
            <div className="fp_instruction">
              We will email instructions for setting a new one
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
